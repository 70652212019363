import {withGlobal} from '../../shared/app'
import {BtnEdit} from '../../widgets/btn-edit'
import moment from 'moment'
import Linkify from 'linkify-react'

const Block = ({page, hasRole, flow, t, uiref = {}, className = ''}) => {
  const editMode = hasRole('manager,broker')

  const getIntro = (index, page = {}) => {
    return t(`edit-intro.templates.${index}`, {
      firstname: page.firstname,
      fullname: page.fullname,
      dod: (page.dod && moment(page.dod).format(t('dates.short'))) || '',
      age: page.age || (page.dob && page.dod && moment(page.dod).diff(page.dob, 'years'))
    })
  }

  const intro = page.intro || getIntro(0, page)

  return (
    <div className={`card ${className}`}>
      <div className='card-body py-4 col-12'>
        <Linkify as='p' className='intro f2 pre-line' options={{target: '_blank'}}>
          {intro}
        </Linkify>
        {editMode && <BtnEdit onClick={() => flow.set('edit-intro', {uiref})} />}
      </div>
    </div>
  )
}

export default withGlobal(Block)
