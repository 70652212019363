import {isMobileLayout} from '../../lib/utils'
import {withGlobal} from '../shared/app'

const isTabActive = (key, current, append = '') => {
  const className = `${append}active`
  if (key == 'info')
    return [false, 'tab-infos', 'edit-profile', 'edit-intro', 'edit-places'].includes(current)
      ? className
      : ''
  if (key == 'memories') return ['tab-memories', 'new-memory'].includes(current) ? className : ''
  if (key == 'share') return ['tab-share'].includes(current) ? className : ''
  if (key == 'settings')
    return ['tab-settings', 'managers', 'ft-donation-settings'].includes(current) ? className : ''
  if (key == 'help') return ['tab-help'].includes(current) ? className : ''
}

const Nav = ({page, authed, t, flow, hasRole, Beacons, navbarHidden, fullscreen}) => {
  if (['profile-setup'].includes(flow.current)) return null

  const help = (e) => {
    if (Beacons.available(t)) {
      e.target.blur()
      Beacons.toggle()
    } else {
      const uiref = {page: flow.current, name: 'tab-help', entity: 'navbar'}

      flow.set('tab-help', {uiref})
    }
  }

  const nav = (f) => {
    const uiref = {page: flow.current, name: f, entity: 'navbar'}

    if (Beacons.available(t) && isMobileLayout()) {
      Beacons.close()
    }

    flow.set(f, {uiref})
  }

  return (
    <div className={`appnav py-3 bg-light no-select ${fullscreen || navbarHidden ? 'd-none' : ''}`}>
      <div className='container p-0'>
        <div className='d-flex justify-content-between align-items-center'>
          {authed && (
            <div className='z1'>
              <ul className='nav d-flex justify-content-between justify-content-md-start'>
                <li className='nav-item d-flex justify-content-center align-items-center ml-md-4'>
                  <a
                    className={`nav-link ${isTabActive('info', flow.current)}`}
                    onClick={() => nav('tab-infos')}>
                    <img
                      className='d-md-none'
                      src={`https://res.cloudinary.com/inmemori/image/upload/v1593089107/web-assets/picto/nav${isTabActive(
                        'info',
                        flow.current,
                        '/'
                      )}/home.svg`}
                    />
                    <span className=''>{t(`appnav.info`)}</span>
                  </a>
                </li>
                <li className='nav-item d-flex justify-content-center align-items-center'>
                  <a
                    className={`nav-link ${isTabActive('memories', flow.current)}`}
                    onClick={() => nav('tab-memories')}>
                    <img
                      className='d-md-none'
                      src={`https://res.cloudinary.com/inmemori/image/upload/v1593089107/web-assets/picto/nav${isTabActive(
                        'memories',
                        flow.current,
                        '/'
                      )}/memories.svg`}
                    />
                    <span className=''>{t(`appnav.memories`)}</span>
                  </a>
                </li>
                <li className='nav-item d-flex justify-content-center align-items-center'>
                  <a
                    className={`nav-link ${isTabActive('share', flow.current)}`}
                    onClick={() => nav('tab-share')}>
                    <img
                      className='d-md-none'
                      src={`https://res.cloudinary.com/inmemori/image/upload/v1593089107/web-assets/picto/nav${isTabActive(
                        'share',
                        flow.current,
                        '/'
                      )}/share.svg`}
                    />
                    <span className=''>{t(`appnav.share`)}</span>
                  </a>
                </li>

                <li className='d-md-none nav-item d-flex justify-content-center align-items-center'>
                  <a
                    className={`nav-link ${isTabActive('settings', flow.current)}`}
                    onClick={() => nav('tab-settings')}>
                    <img
                      className='d-md-none'
                      src={`https://res.cloudinary.com/inmemori/image/upload/v1593089107/web-assets/picto/nav${isTabActive(
                        'settings',
                        flow.current,
                        '/'
                      )}/settings.svg`}
                    />
                    <span className=''>{t(`appnav.settings`)}</span>
                  </a>
                </li>
                <li className='d-md-none nav-item d-flex justify-content-center align-items-center'>
                  <a
                    className={`nav-link helper ${isTabActive('help', flow.current)}`}
                    onClick={help}>
                    <img
                      className='d-md-none'
                      src={`https://res.cloudinary.com/inmemori/image/upload/v1593089107/web-assets/picto/nav${isTabActive(
                        'help',
                        flow.current,
                        '/'
                      )}/help.svg`}
                    />
                    <span className=''>{t(`appnav.help`)}</span>
                  </a>
                </li>
              </ul>
            </div>
          )}

          <div className='z2 text-center d-none d-md-block mx-auto'>
            <img
              className='logo cursor'
              src='https://res.cloudinary.com/inmemori/image/upload/web-assets/brand/logo-pf-black.svg'
              onClick={() => nav('tab-infos')}
            />
          </div>

          {!navbarHidden && authed && (
            <div className='z3 text-right d-none d-md-block'>
              <ul className='nav d-flex justify-content-around justify-content-md-end'>
                {!Beacons.available(t) && (
                  <li className='nav-item d-lg-flex justify-content-center align-items-center mr-4'>
                    <a className={`nav-link ${isTabActive('help', flow.current)}`} onClick={help}>
                      <span className=''>{t(`appnav.help-long`)}</span>
                    </a>
                  </li>
                )}
                <li className='nav-item d-flex justify-content-center align-items-center mr-md-4'>
                  <a
                    className={`nav-link ${isTabActive('settings', flow.current)}`}
                    onClick={() => nav('tab-settings')}>
                    <img
                      className='d-md-none'
                      src={`https://res.cloudinary.com/inmemori/image/upload/v1593089107/web-assets/picto/nav${isTabActive(
                        'settings',
                        flow.current,
                        '/'
                      )}/settings.svg`}
                    />
                    <span className=''>{t(`appnav.settings`)}</span>
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>

        {hasRole('team') && <div className='team-label'>TEAM</div>}
        {false && hasRole('broker') && <div className='team-partner'>PARTNER</div>}
      </div>

      <style jsx>
        {`
          .team-label {
            background: #ff8f60;
            color: white;
            position: fixed;
            top: 15px;
            right: -30px;
            font-weight: bold;
            padding: 5px 40px 5px 40px;
            text-align: center;
            z-index: 10000;
            transform: rotate(45deg);
          }

          .team-partner {
            background: #32735f;
            color: white;
            position: fixed;
            top: 22px;
            right: -35px;
            font-weight: bold;
            padding: 5px 40px 5px 40px;
            text-align: center;
            z-index: 10000;
            transform: rotate(45deg);
          }

          .logo {
            height: 80px;
            max-width: 95% !important;
          }

          .nav-link {
            cursor: pointer;
            border: 0;
            border-bottom: 1px solid transparent;
            padding: 10px 0;
            color: #222222;
          }

          .nav-item {
            margin-right: 36px;
          }
          .z1 .nav-item:last-child,
          .z1 .nav-item:nth-last-child(2) {
            margin-right: 0px !important;
          }

          .nav-link.active {
            border: 0;
            border-bottom: 1px solid #32735f;
            color: #32735f;
          }
          .nav-link:hover {
            border: 0;
            border-bottom: 1px solid #32735f;
          }

          .z1,
          .z3 {
            // flex: 1;
          }

          @media (min-width: 768px) {
            // sm up
            .appnav {
              position: sticky;
              top: 0;
              z-index: 100;
            }
          }

          @media (max-width: 991px) {
            // lg down
            .container {
              width: 100% !important;
              max-width: 100% !important;
            }
          }

          @media (max-width: 767px) {
            // sm down
            .appnav {
              position: fixed;
              width: 100%;
              bottom: 0;
              z-index: 100;
              border-top: 1px solid #dadada;
              // box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.16), 0 -2px 6px 0 rgba(0, 0, 0, 0.12);
              background: #fff !important;
              height: 70px;
              margin: 0 !important;
              padding: 0 !important;
            }

            .nav-item {
              flex: 1 1 0px;
              margin-top: -1px;
            }
            .nav-link {
              min-width: 62px;
              border: 0;
              border-top: 1px solid transparent;
            }

            .nav-link.active {
              border: 1px;
              border-top: 1px solid #32735f;
            }
            .nav-link:hover {
              border: 0;
              border-top: 1px solid #32735f;
            }
            .nav-link.helper:hover {
              border: 0 !important;
            }
            .z1 {
              width: 100%;
            }
            .z1 img {
              height: 24px;
              margin-bottom: 2px;
            }
            .z1 a {
              text-align: center;
            }
            .z1 a span {
              display: block;
              font-size: 10px;
            }

            .nav-item {
              margin-right: 0px;
            }
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(Nav)
