import React, {useEffect} from 'react'
import Head from 'next/head'
import {withGlobal} from '../shared/app'
import Flow from '../store/flow'
import flows from './blocks'

import Panel from '../store/panels'
import panels from './panels'

import Cookies from './cookies'
import Nav from './nav'
import Content from './content'
import SideBar from './sidebar'

import {isMobileLayout} from '../../lib/utils'

const Space = ({page, lang, flow, fullscreen, panel, t, query, Beacons, navbarHidden}) => {
  const reloadBeacon = () => {
    Beacons.destroy()
    Beacons.hideButton()

    if (navbarHidden) {
      return
    }

    //init helpscout beacon
    if (Beacons.init(t)) {
      if (isMobileLayout()) {
        Beacons.hideButton()
      } else {
        Beacons.showButton(t)
      }
    }
  }

  useEffect(() => {
    if (['onboarding', 'splash'].includes(flow.current)) Beacons.destroy()
    else reloadBeacon()

    return () => {
      Beacons.destroy()
    }
  }, [lang, navbarHidden])

  useEffect(() => {
    if (Beacons.available()) {
      Beacons.changePage()
    }

    if (['onboarding', 'splash'].includes(flow.current)) Beacons.destroy()
    else reloadBeacon()
  }, [flow.current])

  const nav = (f) => {
    const uiref = {page: flow.current, name: f, entity: 'navbar'}

    flow.set(f, {uiref})
  }

  return (
    <React.Fragment>
      <div className='push-footer'>
        <div className='top-block bg-light'></div>
        <Nav />
        <Content className='py-0 py-md-3'>
          <div className='z2 text-center d-block d-md-none mx-auto mt-1'>
            <img
              className='logo cursor'
              src='https://res.cloudinary.com/inmemori/image/upload/web-assets/brand/logo-inmemori-black.svg'
              onClick={() => nav('tab-infos')}
            />
          </div>
          <div className='row'>
            <div className='left-panel col-12 col-md-4 col-lg-3'>
              <SideBar />
            </div>
            <div className='right-panel col-12 col-md-8 col-lg-9 pl-0'>
              <div className='flow-container animated fadeIn'>
                <Flow flows={flows} flow={flow.current || query.flow} fullscreen={fullscreen} />
              </div>
            </div>
          </div>
        </Content>
        <Panel panels={panels} panel={panel} />
        <Cookies />
      </div>

      <style jsx global>
        {`
          * {
            // color: red !important;
          }

          .right-panel {
            position: inherit;
          }

          .partner-block {
            justify-content: center;
            padding: 12px 24px;
            gap: 8px;
            background: #303133;
            color: #f5f7fa;
            font-size: 14px;
          }

          .top-block {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 280px;
            z-index: -1;
          }

          .logo {
            height: 80px;
          }

          .flow-container {
            background: #fff !important;
            border-radius: 16px;
          }

          // md down
          @media (max-width: 767px) {
            .flow-container {
              border-radius: 0px;
            }
            .top-block {
              height: 100px;
            }
            .logo {
              height: 20px;
            }
          }
        `}
      </style>
    </React.Fragment>
  )
}

export default withGlobal(Space)
